import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import BreadcrumbMenu from '../components/menus/happeningsbcmenu';
import Events from '../components/happenings/events';
import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import SideNavBar from '../components/menus/happeningsnavbarmenu';
import { graphql } from 'gatsby';

const Detail = ({ data }) => {
  const Happenings = data.allNodeCorporateHappenings
  return (
    <Layout>
      <SEO 
        title={`Happenings`} 
        description={`Happenings`}
        keywords={[`Long Term Care Services`, `Transitional Care`, `Transitional Care Services`, `Genesis Rehab`]} 
      />
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow center>
            <MDBCol xs="12" md="3" lg="3" className="col-12 leftside">
              <SideNavBar />
            </MDBCol>
            <MDBCol xs="12" md="9" lg="9" className="col-12 contentRight">
              <h1 className="pagetitle" 
                style={{backgroundImage: `url(${'/images/banners/SectionsBox/bnr-happenings2-trimmed.png'})`}}>
                Happenings
              </h1>
              <BreadcrumbMenu title="Happenings"/>
              <Events EventList={Happenings}/>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  )
}

export default Detail;

export const query = graphql`
query allHappeningsList {
  allNodeCorporateHappenings(sort: {fields: field_happening_s_date, order: DESC}) {
    edges{
      node {
        field_happening_s_date
        title
        body {
          value
        }
        path {
          alias
        }
      }
    }
  }
}
`